import Cookies from 'js-cookie'

const TOKEN_KEY = 'token'

export function getToken() {
  return Cookies.get(TOKEN_KEY)
}

export function setToken(token) {
  Cookies.set(TOKEN_KEY, token)
}

export function removeToken() {
  Cookies.remove(TOKEN_KEY)
}

const TENANT_ID = 'tenant_id'

export function getTenantId() {
  return Cookies.get(TENANT_ID)
}

export function setTenantId(id) {
  Cookies.set(TENANT_ID, id)
}

export function removeTenantId() {
  Cookies.remove(TENANT_ID)
}

const AUTH_KEY = 'auth_key'

export function getAuthKey() {
  const strData = Cookies.get(AUTH_KEY)
  return strData ? JSON.parse(strData) : null
}

export function setAuthKey(key) {
  Cookies.set(AUTH_KEY, JSON.stringify(key))
}

export function removeAuthKey() {
  Cookies.remove(AUTH_KEY)
}

const USER_KEY = 'user'

export function getUser() {
  const strData = Cookies.get(USER_KEY)
  return strData ? JSON.parse(strData) : null
}

export function setUser(user) {
  Cookies.set(USER_KEY, JSON.stringify(user))
}

export function removeUser() {
  Cookies.remove(USER_KEY)
}

const TENANT_INFO = 'tenant'

export function getTenantInfo() {
  const strData = Cookies.get(TENANT_INFO)
  return strData ? JSON.parse(strData) : null
}

export function setTenantInfo(data) {
  Cookies.set(TENANT_INFO, JSON.stringify(data))
}

export function removeTenantInfo() {
  Cookies.remove(TENANT_INFO)
}

export function removeUserInfo() {
  removeToken()
  removeTenantId()
  removeAuthKey()
  removeUser()
  removeTenantInfo()
}
