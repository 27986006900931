export const PAGE_TITLE = '图南智能管理系统'

export const APP_KEY = '422932638'

export const SELF_APP_ID = 'wwbfb6c1ab251dc8a4'

export const ADMIN_AGENT_ID = '1000006'

export const QYWX_LOGIN_REDIRECT_URI = 'http://admin.iotwisdom.cn'

export const FILES_UPLOAD_URL =
  'https://tucloud.iotwisdom.cn/api/v1/obs/files-upload'

export const SYSTEM_AUTH_TYPE = {
  MODULE: '1',
  MENU: '2',
  FUNCL: '3'
}
