<template>
  <div class="sidebar-container" :class="{ 'has-logo': showLogo }">
    <el-scrollbar wrap-class="scrollbar-wrapper">
      <el-menu
        :default-active="activeMenu"
        :collapse="isCollapse"
        :unique-opened="false"
        :collapse-transition="false"
        mode="vertical"
      >
        <sidebar-item
          v-for="route in siderMenuList"
          :key="route.path"
          :item="route"
          :base-path="route.path"
        />
      </el-menu>
    </el-scrollbar>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import SidebarItem from './SidebarItem'

export default {
  components: { SidebarItem },
  data() {
    return {}
  },
  computed: {
    ...mapGetters(['sidebar', 'dynamicMenu']),
    ...mapState({
      topMenuName: (state) => state.app.topMenuName
    }),
    siderMenuList() {
      if (!this.topMenuName) {
        return []
      }

      const topMenuData = this.dynamicMenu.find(
        (i) => i.name === this.topMenuName
      )

      return topMenuData ? topMenuData.children : []
    },

    activeMenu() {
      // TODO:
      return this.$route.path
    },
    showLogo() {
      return this.$store.state.app.sidebarLogo
    },
    isCollapse() {
      return !this.sidebar.opened
    }
  }
}
</script>
