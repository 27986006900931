import axios from 'axios'
import { Message } from 'element-ui'
import { getToken, getTenantId, removeUserInfo } from '@/utils/auth'
import { APP_KEY } from '@/config'

// create an axios instance
const Request = axios.create({
  baseURL: '/api',
  // withCredentials: true, // send cookies when cross-domain requests
  timeout: 5000 // request timeout
})

// request interceptor
Request.interceptors.request.use(
  (config) => {
    // do something before request is sent
    config.headers['token'] = getToken()
    config.headers['tenant_id'] = getTenantId()
    config.headers['app-key'] = APP_KEY
    config.headers['timestamp'] = new Date().getTime()

    return config
  },
  (error) => {
    // do something with request error
    console.log(error) // for debug
    return Promise.reject(error)
  }
)

// response interceptor
Request.interceptors.response.use(
  (response) => {
    const res = response.data

    if (res.code !== 0) {
      // 请求异常
      Message({
        message: res.message || '请求异常',
        type: 'error',
        duration: 5 * 1000
      })

      return Promise.reject(new Error(res.message || 'Error'))
    } else {
      // 请求成功
      return res.data
    }
  },
  (error) => {
    const { response } = error
    const { status } = response

    if (status === 400 || status === 401) {
      Message({
        message: '用户信息失效，请重新登录',
        type: 'error',
        duration: 5 * 1000
      })

      removeUserInfo()
      location.reload()
    } else {
      Message({
        message: '请求失败，请稍后再试',
        type: 'error',
        duration: 5 * 1000
      })

      return Promise.reject(error)
    }
  }
)

export default Request
