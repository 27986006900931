import Vue from 'vue'
import Router from 'vue-router'
import Layout from '@/layout/layout'
import SidebarContainer from '@/layout/sidebarContainer'
import { isAvaArray } from '@/utils/validate'

Vue.use(Router)

const routerPush = Router.prototype.push
Router.prototype.push = function push(location) {
  return routerPush.call(this, location).catch((error) => error)
}

/**
 * constantRoutes
 * a base page that does not have permission requirements
 */
export const constantRoutes = [
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/login/index'),
    meta: {
      isNotRequireLogin: true
    }
  },
  {
    path: '/404',
    name: '404',
    component: () => import('@/views/error-page/404'),
    meta: {
      isNotRequireLogin: true
    }
  },
  {
    path: '/401',
    name: '401',
    component: () => import('@/views/error-page/401'),
    meta: {
      isNotRequireLogin: true
    }
  },
  {
    path: '/',
    name: 'index',
    component: Layout
  }
]

/**
 * dynamicRoute动态路由
 * 必须设置key值
 */
export const dynamicRoutes = [
  {
    path: `/dashbord`,
    component: () => import('@/views/dashboard/index'),
    name: `dashbord`,
    meta: {
      title: '控制台',
      icon: 'dashbord',
      key: 'dashbord'
    }
  },

  {
    path: `/marketing`,
    name: `marketing`,
    meta: {
      title: '营销管理',
      icon: 'marketing'
    },
    children: [
      {
        path: `/marketing/consult`,
        name: `marketing-consult`,
        meta: {
          title: '询单管理',
          icon: 'consult'
        },
        children: [
          {
            path: `/marketing/consult/list`,
            name: `marketing-consult-list`,
            meta: {
              title: '询单列表',
              key: 'marketing' // TODO:
            },
            component: () => import('@/views/consult/index')
          },
          {
            path: `/marketing/consult/config`,
            name: `marketing-consult-config`,
            meta: {
              title: '询单配置',
              key: 'marketing' // TODO:
            },
            component: () => import('@/views/consult/index')
          }
        ]
      },
      {
        path: `/marketing/product`,
        name: `marketing-product`,
        meta: {
          title: '产品管理',
          icon: 'product',
          key: 'marketing' // TODO:
        },
        component: () => import('@/views/product/index')
      },
      {
        path: `/marketing/content`,
        name: `marketing-content`,
        meta: {
          title: '内容管理',
          icon: 'content'
        },
        children: [
          {
            path: `/marketing/content/list`,
            name: `marketing-content-list`,
            meta: {
              title: '内容列表',
              key: 'marketing' // TODO:
            },
            component: () => import('@/views/content/list')
          },
          {
            path: `/marketing/content/detail`,
            name: `marketing-content-detail`,
            meta: {
              title: '内容详情',
              hidden: true,
              key: 'marketing' // TODO:
            },
            component: () => import('@/views/content/detail')
          },
          {
            path: `/marketing/content/classify`,
            name: `marketing-content-classify`,
            meta: {
              title: '内容分类',
              key: 'marketing' // TODO:
            },
            component: () => import('@/views/content/classify')
          }
        ]
      },

      {
        path: `/marketing/activity`,
        name: `marketing-activity`,
        meta: {
          title: '活动管理',
          icon: 'activity',
          key: 'marketing' // TODO:
        },
        component: () => import('@/views/activity/index')
      },

      {
        path: `/marketing/words`,
        name: `marketing-words`,
        meta: {
          title: '话术库',
          icon: 'words'
        },
        children: [
          {
            path: `/marketing/words/list`,
            name: `marketing-words-list`,
            meta: {
              title: '话术列表',
              key: 'marketing' // TODO:
            },
            component: () => import('@/views/words/list')
          },
          {
            path: `/marketing/words/classify`,
            name: `marketing-words-classify`,
            meta: {
              title: '话术分类',
              key: 'marketing' // TODO:
            },
            component: () => import('@/views/words/classify')
          },
          {
            path: `/marketing/words/market`,
            name: `marketing-words-market`,
            meta: {
              title: '话术市场',
              key: 'marketing' // TODO:
            },
            component: () => import('@/views/words/market')
          }
        ]
      },

      {
        path: `/marketing/materials`,
        name: `marketing-materials`,
        meta: {
          title: '物料管理',
          icon: 'materials'
        },
        children: [
          {
            path: `/marketing/materials/banner`,
            name: `marketing-materials-banner`,
            meta: {
              title: '轮播图管理',
              key: 'marketing' // TODO:
            },
            component: () => import('@/views/materials/banner')
          },
          {
            path: `/marketing/materials/qrcode`,
            name: `marketing-materials-qrcode`,
            meta: {
              title: '二维码管理',
              key: 'marketing' // TODO:
            },
            component: () => import('@/views/materials/qrcode')
          }
        ]
      }
    ]
  },

  {
    path: `/client`,
    name: `client`,
    meta: {
      title: '客户管理',
      icon: 'client'
    },
    children: [
      {
        path: `/client/list`,
        name: `client-list`,
        meta: {
          title: '客户列表',
          icon: 'client',
          key: 'client' // TODO:
        },
        component: () => import('@/views/consult/index')
      },

      {
        path: `/client/label`,
        name: `client-label`,
        meta: {
          title: '标签管理',
          icon: 'label'
        },
        children: [
          {
            path: `/client/label/list`,
            name: `client-label-list`,
            meta: {
              title: '标签列表',
              key: 'client' // TODO:
            },
            component: () => import('@/views/label/list')
          }
        ]
      }
    ]
  },

  {
    path: `/system`,
    name: `system`,
    meta: {
      title: '系统管理',
      icon: 'system'
    },
    children: [
      {
        path: `/system/set`,
        name: `system-set`,
        meta: {
          title: '系统设置',
          icon: 'sys'
        },
        children: [
          {
            path: `/system/set/user`,
            component: () => import('@/views/system/user'),
            name: `system-set-user`,
            meta: {
              title: '用户管理',
              key: 'system' // TODO:
            }
          },
          {
            path: `/system/set/role`,
            component: () => import('@/views/system/role'),
            name: `system-set-role`,
            meta: {
              title: '角色管理',
              key: 'system' // TODO:
            }
          }
          // {
          //   path: `/system/set/menu`,
          //   component: () => import('@/views/system/menu'),
          //   name: `system-set-menu`,
          //   meta: {
          //     title: '菜单管理'
          //   }
          // }
        ]
      },
      {
        path: `/system/authorize`,
        name: `system-authorize`,
        meta: {
          title: '授权管理',
          icon: 'auth'
        },
        children: [
          {
            path: `/system/authorize/qywx`,
            component: () => import('@/views/authorize/qywx'),
            name: `system-authorize-qywx`,
            meta: {
              title: '企业微信设置',
              key: 'system'
            }
          },
          {
            path: `/system/authorize/wechat`,
            component: () => import('@/views/authorize/wechat'),
            name: `system-authorize-wechat`,
            meta: {
              title: '微信公众号设置',
              key: 'system'
            }
          },
          {
            path: `/system/authorize/miniprogram`,
            component: () => import('@/views/authorize/miniprogram'),
            name: `system-authorize-miniprogram`,
            meta: {
              title: '微信小程序设置',
              key: 'system'
            }
          }
        ]
      },
      {
        path: `/system/dict`,
        name: `system-dict`,
        meta: {
          title: '字典管理',
          icon: 'dict'
        },
        children: [
          {
            path: `/system/dict/action`,
            component: () => import('@/views/dict/action'),
            name: `system-dict-action`,
            meta: {
              title: 'Action配置',
              key: 'system' // TODO:
            }
          },
          {
            path: `/system/dict/other`,
            component: () => import('@/views/dict/action'),
            name: `system-dict-other`,
            meta: {
              title: 'xx管理',
              key: 'system' // TODO:
            }
          }
        ]
      }
    ]
  },

  {
    path: `/tenant`,
    name: `tenant`,
    meta: {
      title: '租户管理',
      icon: 'tenant'
    },
    children: [
      {
        path: `/tenant/list`,
        component: () => import('@/views/tenant/list'),
        name: `tenant-list`,
        meta: {
          title: '租户列表',
          icon: 'tenant',
          key: 'tenant'
        }
      },
      {
        path: `/tenant/user`,
        component: () => import('@/views/tenant/user'),
        name: `tenant-user`,
        meta: {
          title: '用户管理',
          icon: 'tenant',
          key: 'tenant'
        }
      },
      {
        path: `/tenant/application`,
        component: () => import('@/views/tenant/application'),
        name: `tenant-application`,
        meta: {
          title: '应用配置',
          icon: 'tenant',
          key: 'tenant'
        }
      },
      {
        path: `/tenant/role`,
        component: () => import('@/views/tenant/role'),
        name: `tenant-role`,
        meta: {
          title: '默认角色管理',
          icon: 'tenant',
          key: 'tenant' // TODO:
        }
      },
      {
        path: `/tenant/auth`,
        component: () => import('@/views/tenant/auth'),
        name: `tenant-auth`,
        meta: {
          title: '菜单管理',
          icon: 'tenant',
          key: 'tenant' // TODO:
        }
      }
    ]
  }
]

/**
 * 根据menuKey生成动态菜单
 */
export const generateDynamicMenu = (routeList = [], authKeys = []) => {
  return routeList.filter((item) => {
    if (isAvaArray(item.children)) {
      item.children = generateDynamicMenu(item.children, authKeys)
      return item.children.length > 0
    }
    return authKeys.includes(item?.meta?.key)
  })
}

/**
 * 根据动态菜单生成动态路由结构
 */
export const generateDynamicRoute = (
  router,
  menuData,
  index = 0,
  parentName = 'index',
  keys = []
) => {
  menuData.forEach((menu) => {
    const children = menu.children

    if (!children || (Array.isArray(children) && children.length === 0)) {
      // 只有一级菜单，直接添加
      router.addRoute(parentName, menu)
    } else {
      if (index === 0) {
        router.addRoute('index', {
          ...menu,
          component: SidebarContainer,
          children: null
        })

        parentName = menu.name
      }

      generateDynamicRoute(router, menu.children, index + 1, parentName, keys)
    }
  })

  router.addRoute({
    path: '*',
    redirect: '/404'
  })
}

/**
 * 获取叶子节点的name
 */
export const getLeafMenu = (menuData) => {
  return isAvaArray(menuData.children)
    ? getLeafMenu(menuData.children[0])
    : menuData.name
}

export default new Router({
  mode: 'history', // require service support
  scrollBehavior: () => ({
    y: 0
  }),
  routes: constantRoutes
})
