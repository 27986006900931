<template>
  <div style="height: 100%">
    <!-- 侧边栏 -->
    <sidebar />

    <!-- 主内容区 -->
    <app-main />
  </div>
</template>

<script>
import { AppMain, Sidebar } from './components'

export default {
  components: {
    AppMain,
    Sidebar
  }
}
</script>

<style lang="scss" scoped></style>
