<template>
  <div class="header-container">
    <div class="header-left">
      <logo v-if="showLogo" :collapse="isCollapse" />

      <hamburger
        id="hamburger-container"
        :is-active="sidebar.opened"
        class="hamburger-container"
        @toggleClick="toggleSideBar"
      />

      <el-menu
        background-color="#fff"
        text-color="#7D86A9"
        :default-active="topActiveIndex"
        class="tn-top-menu"
        mode="horizontal"
        @select="handleTopMenuSelect"
      >
        <el-menu-item
          v-for="item in topMenuList"
          :key="item.name"
          :index="item.name"
        >
          <i :class="`iconfont icon-${item.meta.icon}`"></i>
          <span slot="title">{{ item.meta.title }}</span>
        </el-menu-item>
      </el-menu>
    </div>

    <div class="header-right">
      <el-dropdown
        class="avatar-container right-menu-item hover-effect"
        @command="handleCommand"
      >
        <div class="el-dropdown-link user-box">
          <div class="ava">
            <img class="img-common" src="~@/assets/user.png" />
          </div>

          <div class="user-name">{{ userInfo.mobile }}</div>
        </div>

        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item command="logout">退出登录</el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { isAvaArray } from '@/utils/validate'
import { getUser, removeUserInfo } from '@/utils/auth'
import Hamburger from '@/components/Hamburger'
import { logout } from '@/api/login'
import { getLeafMenu } from '@/router'
import Logo from './Logo'

export default {
  components: {
    Hamburger,
    Logo
  },
  data() {
    return {
      topActiveIndex: '',
      userInfo: getUser()
    }
  },
  computed: {
    ...mapGetters(['sidebar', 'dynamicMenu']),
    showLogo() {
      return this.$store.state.app.sidebarLogo
    },
    isCollapse() {
      return !this.sidebar.opened
    },

    topMenuList() {
      return this.dynamicMenu.filter((i) => {
        if (i.meta && i.meta.hidden) {
          return false
        }

        return true
      })
    }
  },
  watch: {
    $route: function () {
      this.setTopMenuAct()
    }
  },
  methods: {
    setTopMenuAct() {
      const currentRouteName = this.$route.name
      const routeArr = currentRouteName.split('-')

      if (routeArr.length > 0) {
        this.topActiveIndex = routeArr[0]
        this.$store.dispatch('app/setTopMenu', this.topActiveIndex)
      }
    },

    handleTopMenuSelect(name) {
      this.$store.dispatch('app/setTopMenu', name)

      // 默认跳转到该菜单下的第一个子菜单
      const targetMenu = this.topMenuList.find((i) => i.name === name)
      const childrenMenuList = targetMenu.children

      if (isAvaArray(childrenMenuList)) {
        this.$router.push({
          name: getLeafMenu(childrenMenuList[0])
        })
      } else {
        this.$router.push({
          name: targetMenu.name
        })
      }
    },

    toggleSideBar() {
      this.$store.dispatch('app/toggleSideBar')
    },
    handleCommand(key) {
      if (key === 'logout') {
        this.logoutHandle()
      }
    },
    async logoutHandle() {
      try {
        await logout()
      } catch (error) {
        console.log(error)
      } finally {
        removeUserInfo()
        this.$store.dispatch('app/clearAppStore')
        this.$router.push({
          name: 'login'
        })
      }
    }
  },
  created() {
    this.setTopMenuAct()
  }
}
</script>

<style lang="scss" scoped>
.header-container {
  height: 50px;
  overflow: hidden;
  position: relative;
  background-color: #fff;
  box-shadow: 0 2px 10px 0 rgb(48 48 48 / 6%);

  z-index: 999;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .header-left {
    display: flex;
    align-items: center;

    .hamburger-container {
      line-height: 46px;
      height: 100%;
      cursor: pointer;
      transition: background 0.3s;
      -webkit-tap-highlight-color: transparent;

      &:hover {
        background: rgba(0, 0, 0, 0.025);
      }
    }

    .tn-top-menu {
      border: none;

      .el-menu-item {
        height: 50px;
        line-height: 50px;

        &.el-menu-item.is-active {
          color: $primary;
        }
      }
    }
  }

  .header-right {
    height: 100%;
    line-height: 50px;

    &:focus {
      outline: none;
    }

    .right-menu-item {
      display: inline-block;
      padding: 0 8px;
      height: 100%;
      font-size: 18px;
      // color: #fff;
      color: #97a8be;
      vertical-align: text-bottom;

      &.hover-effect {
        cursor: pointer;
        transition: background 0.3s;

        &:hover {
          background: rgba(0, 0, 0, 0.025);
        }
      }
    }

    .user-box {
      display: flex;
      align-items: center;
      .ava {
        overflow: hidden;
        border-radius: 50%;
        height: 30px;
        width: 30px;
      }

      .user-name {
        margin-left: 5px;
        color: rgb(125, 134, 169);
        font-size: 14px;
      }
    }

    .avatar-container {
      margin-right: 10px;
      display: flex;
      align-items: center;
    }
  }
}
</style>
