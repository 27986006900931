import router, { generateDynamicRoute } from './router'
import store from './store'

import NProgress from 'nprogress' // progress bar
import 'nprogress/nprogress.css' // progress bar style
import { removeUserInfo, getToken, getAuthKey } from '@/utils/auth' // eslint-disable-line
import getPageTitle from '@/utils/get-page-title'
import { isAvaArray } from '@/utils/validate'

NProgress.configure({ showSpinner: false }) // NProgress Configuration

router.beforeEach(async (to, from, next) => {
  // start progress bar
  NProgress.start()

  // set page title
  document.title = getPageTitle(to.meta.title)

  if (to?.meta?.isNotRequireLogin) {
    next()
    return
  }

  // determine whether the user has logged in
  const hasToken = getToken()

  if (hasToken) {
    const dynamicMenu = store.getters.dynamicMenu

    if (isAvaArray(dynamicMenu)) {
      // 存在动态路由，直接放行
      if (to.name === 'index') {
        // 将要跳转到首页
        next({
          name: dynamicMenu[0].name
        })
      } else {
        next()
      }
      NProgress.done()
    } else {
      /**
       * 不存在动态路由，则需要构建
       * 根据动态菜单，构建动态路由
       * TODO: 退出后需要清理，否则需要刷新才能看到新权限数据
       */
      try {
        const dynamicMenu = await store.dispatch(
          'app/getDynamicMenu',
          getAuthKey()
        )

        if (isAvaArray(dynamicMenu)) {
          // 新增动态路由
          generateDynamicRoute(router, dynamicMenu)

          console.log('router----')
          console.log(router.getRoutes())

          if (to.name === 'index') {
            next({
              name: dynamicMenu[0].name
            })
            NProgress.done()
          } else {
            next({ ...to })
          }
        } else {
          // 当前用户未拥有任何菜单权限，则跳转到无权限提示页 TODO:
          next({
            name: '401'
          })
          NProgress.done()
        }
      } catch (error) {
        // TODO: 重定向到500
        next({
          name: '401'
        })
        NProgress.done()
      }
    }
  } else {
    // 不存在跳转到主系统登录页
    removeUserInfo()
    next({
      name: 'login'
    })
    NProgress.done()
  }
})

router.afterEach(() => {
  // finish progress bar
  NProgress.done()
})
