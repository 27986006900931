import request from '@/utils/request'

export function mobileLogin(data) {
  return request({
    url: '/v1/tenant/login/mobile',
    method: 'post',
    data
  })
}

export function qywxLogin(data) {
  return request({
    url: '/v1/qywx/work-user-info',
    method: 'post',
    data
  })
}

export function getUserInfo(data) {
  return request({
    url: '/v1/tenant/index/detail',
    method: 'get',
    params: data
  })
}

export function logout(data) {
  return request({
    url: '/v1/passport/login-out',
    method: 'post',
    data
  })
}
