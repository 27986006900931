import Cookies from 'js-cookie'
import cloneDeep from 'lodash.clonedeep'
import { dynamicRoutes, generateDynamicMenu } from '@/router'

const state = {
  sidebarLogo: true,

  sidebar: {
    opened: Cookies.get('sidebarStatus')
      ? !!+Cookies.get('sidebarStatus')
      : true,
    withoutAnimation: false
  },
  device: 'desktop',

  topMenuName: '', // 顶部菜单名称

  dynamicMenu: [] // 动态菜单
}

const mutations = {
  TOGGLE_SIDEBAR: (data) => {
    data.sidebar.opened = !data.sidebar.opened
    data.sidebar.withoutAnimation = false
    if (data.sidebar.opened) {
      Cookies.set('sidebarStatus', 1)
    } else {
      Cookies.set('sidebarStatus', 0)
    }
  },
  CLOSE_SIDEBAR: (dataSideBar, withoutAnimation) => {
    Cookies.set('sidebarStatus', 0)
    dataSideBar.sidebar.opened = false
    dataSideBar.sidebar.withoutAnimation = withoutAnimation
  },
  TOGGLE_DEVICE: (dataSideBar, device) => {
    dataSideBar.device = device
  },

  SET_TOP_MENU: (state, name) => {
    state.topMenuName = name
  },

  SET_DYNAMIC_MENU: (state, list) => {
    state.dynamicMenu = list
  },

  CLEAR_APP_STORE: (state) => {
    state.topMenuName = ''
    state.dynamicMenu = []
  }
}

const actions = {
  toggleSideBar({ commit }) {
    commit('TOGGLE_SIDEBAR')
  },
  closeSideBar({ commit }, { withoutAnimation }) {
    commit('CLOSE_SIDEBAR', withoutAnimation)
  },
  toggleDevice({ commit }, device) {
    commit('TOGGLE_DEVICE', device)
  },

  setTopMenu({ commit }, name) {
    commit('SET_TOP_MENU', name)
  },

  async getDynamicMenu({ commit }, authKeys) {
    try {
      const dynamicMenu = generateDynamicMenu(
        cloneDeep(dynamicRoutes),
        authKeys
      )

      commit('SET_DYNAMIC_MENU', dynamicMenu)
      return dynamicMenu
    } catch (error) {
      return Promise.reject(error || new Error('Error'))
    }
  },

  clearAppStore({ commit }) {
    commit('CLEAR_APP_STORE')
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
