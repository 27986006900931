<template>
  <div
    class="app-wrapper"
    :class="{
      hideSidebar: !this.sidebar.opened,
      openSidebar: this.sidebar.opened,
      withoutAnimation: this.sidebar.withoutAnimation
    }"
  >
    <div class="main-container">
      <!-- 头部 -->
      <Header />

      <!-- 主内容区 -->
      <div class="sub-main-container">
        <router-view />
      </div>
    </div>
  </div>
</template>

<script>
import { Header } from './components'
import { mapState } from 'vuex'

export default {
  components: {
    Header
  },
  computed: {
    ...mapState({
      sidebar: (state) => state.app.sidebar
    })
  }
}
</script>

<style lang="scss" scoped></style>
